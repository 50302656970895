import { defineComponent } from 'vue';
const __default__ = defineComponent({
    props: {
        variant: {
            type: String,
            default: 'body',
        },
        textWeight: {
            type: String,
            default: '700' // Default weight is (Bold), other options: '400', '500', '700'
        },
        textColor: {
            type: String,
            default: '#0C0F4A' // Default color is #0C0F4A
        },
        textSize: {
            type: String,
            default: '1rem'
        },
        whiteSpace: {
            type: String,
            default: 'normal'
        },
        lineHeight: {
            type: String,
            default: '24px'
        },
        isItalic: {
            type: Boolean,
            default: false
        },
        xsMobileFont: {
            type: String,
            default: '' // xs font-size if responsive. options: 14, 20, 24
        },
        textAlign: {
            type: String,
            default: ''
        }
    },
    computed: {
        textClass() {
            return `typography-${this.variant}`;
        },
        italicClass() {
            if (this.isItalic) {
                return 'italic';
            }
            return '';
        },
        xsMobileFontClass() {
            if (this.xsMobileFont !== '') {
                return `xs-fs-${this.xsMobileFont}`;
            }
            return '';
        }
    },
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "0837a84e": (_ctx.$props.textWeight),
        "e8dc9266": (_ctx.$props.textColor),
        "bace1eb4": (_ctx.$props.whiteSpace),
        "751bf084": (_ctx.$props.lineHeight),
        "e917c322": (_ctx.$props.textAlign),
        "0487c357": (_ctx.$props.textSize)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
