<template>
  <div class="icon-class flex-row ai-c jc-c" :class="[xsMobileSizeClass]">
    <img
      class="icon-class__img"
      :src="require(`@/assets/${customPath}/${iconName}`)">
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    iconWidth: {
      type: String,
      default: '' // Make sure to add px e.g 40px
    },

    iconHeight: {
      type: String,
      default: '' // Make sure to add px e.g 40px
    },

    iconName: {
      type: String,
      default: 'add.svg' // Make sure to add extension e.g add.svg or add.png
    },

    xsMobileSize: {
      type: String, // xs mobile screen icon screen size. Options: 30, 32, 40, 42  // NOTE: this is just for now.
      default: ''
    },

    customPath: {
      type: String,
      default: 'icons'
    }
  },

  computed: {
    xsMobileSizeClass() {
      if (this.xsMobileSize !== '') {
        return `xs-mobile-size-${this.xsMobileSize}`;
      }
      return '';
    },

    fullPath() {
      return this.customPath.concat(this.iconName);
    }
  }
});
</script>
<style lang="scss" scoped>
  .icon-class {
    min-width: v-bind('$props.iconWidth');
    min-height: v-bind('$props.iconHeight');
    width: v-bind('$props.iconWidth');
    height: v-bind('$props.iconHeight');

    &__img {
      width: 100%;
      height: 100%;
    }
  }

  .xs-mobile-size-30 {
    min-width: 30px;
    min-height: 30px;
  }
  .xs-mobile-size-32 {
    min-width: 32px;
    min-height: 32px;
  }
  .xs-mobile-size-40 {
    min-width: 40px;
    min-height: 40px;
  }
  .xs-mobile-size-42 {
    min-width: 42px;
    min-height: 42px;
  }
</style>
